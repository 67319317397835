import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getCookieValue } from "./App";
import { t } from "./Util_format";
import "./css/Unauthenticated.css";

export default function Modal_Waiting_Push({
  onClose,
  errorMessage,
  isProcessing,
  onForceSendSMS,
  onAction,
}) {
  const [timer, setTimer] = useState(5);
  const intervalRef = useRef();
  useEffect(() => {
    if (getCookieValue("double_auth_otp_delivery_method") === "push") {
      intervalRef.current = setInterval(async () => {
        onAction();
      }, 5000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [onAction]);

  useEffect(() => {
    if (timer > 0) {
      const timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [timer]);

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="modal_title" sx={{ color: "green !important" }}>
        {t("waiting_to_approve_from_mobile")}
      </DialogTitle>
      <DialogContent>
        {errorMessage && (
          <div className="error-container_modal">
            <div className="error-icon">!</div>
            <Typography className="error-text">{errorMessage}</Typography>
          </div>
        )}

        {timer > 0 ? (
          <div>
            <div
              style={{
                position: "relative",
                width: "50px",
                height: "50px",
                marginTop: " 20px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <CircularProgress
                variant="determinate"
                value={(100 * timer) / 5}
                size={50}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontWeight: "bold",
                }}
              >
                {timer}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Typography
              variant="body1"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              {t("prefer_to_send_a_sms_instead")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={`unauthenticated_button ${
                isProcessing ? "processingButton" : ""
              }`}
              disabled={isProcessing}
              onClick={onForceSendSMS}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("send_sms")
              )}
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
