import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Typography, createTheme } from '@mui/material';
import translations from './config/translations.json';
import { getCookieValue } from './App';
import config from './config/env.json';
import { useNavigate } from 'react-router-dom';
import { t } from './Util_format';
import { theme } from './ReactStyles';
import { get_api_url } from './Util_API_calls';

function Modal_CreateBeneficiary({ beneficiary, onClose, isTransferDirect, onTransferDirect }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentType, setPaymentType] = useState('SEPA'); // default payment type
  const navigate = useNavigate();

  const [beneficiaryDetails, setBeneficiaryDetails] = useState({
    name: '',
    surname: '',
    iban: '',
    email: '',
  });

  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBeneficiaryDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle payment type change
  const handlePaymentTypeChange = (event) => {
    const { value } = event.target;
    setPaymentType(value);
  };

  const handleSave = async () => {
    setIsProcessing(true);
  
    const token = getCookieValue('firebaseToken');
    const { name, surname, iban, email } = beneficiaryDetails;
    const sanitizedIban = iban.replace(/\s+/g, '');
  
    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)?$/;
    const surnameRegex = /^[A-Za-z]+(?: [A-Za-z]+)?$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!nameRegex.test(name) || !surnameRegex.test(surname)) {
      setErrorMessage(t('error_invalid_name_or_surname'));
      setIsProcessing(false);
      return;
    }
  
    if (!emailRegex.test(email)) {
      setErrorMessage(t('error_invalid_email'));
      setIsProcessing(false);
      return;
    }

    if (sanitizedIban.trim().length < 5) {
      setErrorMessage(t('iban_is_not_valid'));
      setIsProcessing(false);
      return;
    }
  
    setErrorMessage('');
  
    const createRequestOptions = (headers, body) => ({
      method: 'POST',
      headers,
      body: new URLSearchParams(body),
    });
  
    try {
      const API_URL = await get_api_url();
  
      if (isTransferDirect) {
        const response = await fetch(`${API_URL}/api/baas/utilities/iban_check`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ iban: sanitizedIban }),
        });
  
        if (!response.ok) {
          const errorBody = await response.json();
          throw { response, error: errorBody };
        }
  
        const data = await response.json();
        const bic = data.data.bank.bic.length === 8
          ? `${data.data.bank.bic}XXX`
          : data.data.bank.bic;
  
        const tempBeneficiary = {
          payment_type: paymentType,
          beneficiary_iban: sanitizedIban,
          bank_bic: bic,
          beneficiary_name: name,
          beneficiary_surname: surname,
          beneficiary_currency: 'EUR',
          bank_country_code: data.data.country_code,
          beneficiary_email: email,
          beneficiary_address: data.data.bank.address,
        };
  
        onTransferDirect(tempBeneficiary);
      } else {
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        };
  
        const commonBody = {
          payment_type: paymentType,
          beneficiary_type: 'individual',
          beneficiary_name: name,
          beneficiary_surname: surname,
          beneficiary_iban: sanitizedIban,
          beneficiary_email: email,
        };
  
        const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');
        const body = RETAIL_OR_BUSINESS === 'BUSINESS'
          ? { ...commonBody, business_id: getCookieValue('business_id') }
          : commonBody;
  
        const response = await fetch(`${API_URL}/api/baas/beneficiaries/create`, createRequestOptions(headers, body));
  
        if (!response.ok) {
          const errorBody = await response.json();
          throw { response, error: errorBody };
        }
  
        const data = await response.json();
        navigate(`/bank-transfers/make-payment/${data.beneficiary_id}`);
        window.location.reload();
      }
    } catch (error) {
      console.error('Error:', error);
      handleErrorResponse(error.response, error.error);
    } finally {
      setIsProcessing(false);
    }
  };
  
  const handleErrorResponse = (response, error) => {
    const handleResponse = () => {
      if (response) {
        switch (response.status) {
          case 400:
            if (error === 'IBAN already exists for the customer') {
              setErrorMessage(t('duplicated_iban'));
            } else {
              setErrorMessage(t('system_error_try_again_later'));
            }
            break;
          case 401:
            setErrorMessage(t('iban_is_not_valid'));
            break;
          case 422:
            setErrorMessage(t('please_fill_all_mandatory_inputs'));
            break;
          default:
            setErrorMessage(t('system_error_try_again_later'));
            break;
        }
      } else {
        setErrorMessage(t('network_error_unable_to_save_changes'));
      }
    }
    if (error) {
      if (
        error.fdt_error_code === "BAD_IBAN" ||
        (error.details &&
          error.details.iban &&
          Array.isArray(error.details.iban) &&
          error.details.iban.length > 0 &&
          error.details.iban[0] === "The iban format is invalid.")
      ) {
        setErrorMessage(t("iban_is_not_valid"));
      } else {
        handleResponse();
      }
    } else {
      handleResponse();
    }
  };
  

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className='modal_title'>{t(isTransferDirect ? 'new_bank_transfer' : 'create_new_beneficiary_title')}</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <div >
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        <TextField
          select
          label={t('beneficiary_payment_type')}
          name="paymentType"
          fullWidth
          margin="dense"
          value={paymentType}
          onChange={handlePaymentTypeChange}
        >
          <MenuItem key="SEPA" value="SEPA">
            {t('new_bank_transfer_sepa')}
          </MenuItem>
          <MenuItem key="SWIFT" value="SWIFT">
            {t('new_bank_transfer_swift')}
          </MenuItem>
        </TextField>

        <TextField
          autoFocus
          margin="dense"
          id="beneficiary_name"
          name="name"
          label={t('beneficiary_name')}
          type="text"
          fullWidth
          value={beneficiaryDetails.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="beneficiary_surname"
          name="surname"
          label={t('beneficiary_surname')}
          type="text"
          fullWidth
          value={beneficiaryDetails.surname}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="beneficiary_iban"
          name="iban"
          label={t('beneficiary_iban')}
          type="text"
          fullWidth
          value={beneficiaryDetails.iban}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="beneficiary_email"
          name="email"
          label={t('beneficiary_email')}
          type="email"
          fullWidth
          value={beneficiaryDetails.email}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={isProcessing}
        >
          {isProcessing ? <CircularProgress size={24} /> : t(isTransferDirect ? 'next_button' : 'save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal_CreateBeneficiary;